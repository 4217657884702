import '@/scss/main.scss'
import Vue from 'vue'
import Buefy from 'buefy'
import router from './router'
import store from './store'
import App from './App.vue'
import Axios from 'axios'
import * as dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';

import Clipboard from 'v-clipboard'
import { getToken } from '@/services/msal-service'
import vSelect from 'vue-select'

Vue.config.productionTip = true;
Vue.config.devtools = true
Vue.prototype.$dayjs = dayjs
dayjs.extend(customParseFormat);

Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

const axiosBase = Axios.create({});
Vue.prototype.$axiosBase = axiosBase;

Vue.component('v-select', vSelect)

Axios.interceptors.request.use(function (config) {
    var token
    token = store.state.userRawIdToken

    config.headers.Authorization = token ? `Bearer ${token}` : ''


    return config
}, function (error) {
    return Promise.reject(error)
})

Axios.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;

    if (error.response) {
        // Request made and server responded
        var isAuthorisationError = error.response.status === 403 || error.response.status === 401;

        if (isAuthorisationError && !originalRequest._retry) {
            originalRequest._retry = true;

            await getToken()    

            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.userRawIdToken;

            if (originalRequest.data && typeof originalRequest.data === 'string')
                originalRequest.data = JSON.parse(originalRequest.data); //otherwise the content type is set as text not json on second attempt

            return Axios(originalRequest);
        }

    } else if (error.request) {
        // The request was made but no response was received
    } else {
        // Something happened in setting up the request that triggered an Error
    }

    return Promise.reject(error);
});

const defaultDocumentTitle = 'Pocketmags'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
    if (to.name === 'Login') {
        store.commit('asideMobileStateToggle', false)
    } else {
        store.commit('asideMobileStateToggle', true)
    }


    if (to.meta.title) {
        document.title = `${to.meta.title} � ${defaultDocumentTitle}`
    } else {
        document.title = defaultDocumentTitle
    }
})

Vue.use(Clipboard)
Vue.use(Buefy, {
  defaultSnackbarDuration: 10000, // Set your default duration here (in milliseconds)
  defaultToastDuration: 10000,
  defaultModalScroll: "keep"
});

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');