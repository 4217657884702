import * as msal from "@azure/msal-browser";
import store from '../store'

const config = {
    auth: {
        clientId: '0b91a4cd-5981-4eda-8cd7-d79e5e275647',
        authority: 'https://login.microsoftonline.com/e2458d43-9af5-4562-86b3-2056da5b537e',
        redirectUri: process.env.VUE_APP_REDIRECTURI,
        postLogoutRedirectUri: process.env.VUE_APP_POSTLOGOUTURL,
        navigateToLoginRequestUrl: false,
        tenantId: 'e2458d43-9af5-4562-86b3-2056da5b537e'
    },
    cache: {
        cacheLocation: "localStorage", // Use localStorage instead of sessionStorage
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case msal.LogLevel.Error:
                        console.error(message);
                        break;
                    case msal.LogLevel.Info:
                        console.info(message);
                        break;
                    case msal.LogLevel.Verbose:
                        console.debug(message);
                        break;
                    case msal.LogLevel.Warning:
                        console.warn(message);
                        break;
                }
            },
            piiLoggingEnabled: false,
            logLevel: msal.LogLevel.Verbose,
        }
    }
}

export let auth = new msal.PublicClientApplication(config);

export const getToken = () => silentToken()
export const msalConfig = config;

export function handleResponse(response) {
    if (response) {
        const { account } = response;
        auth.setActiveAccount(account); // Save the user's account globally for future requests
        return account;
    }
    return null;
}

export function storeUser(response) {
    const account = auth.getAllAccounts();
    var email
    if (response.account.username === undefined && account.length > 0) {
        email = account[0].username
        store.commit('userEmail', account[0].username)
    } else {
        email = response.account.userName !== undefined ? response.account.userName : response.account.username
    }
  
    var user = {
        expiresOn: response.expiresOn,
        account: {
            name: response.account.name,
            userName: email,
            sid: response.idTokenClaims.sid
        },
        idTokenClaims: {
            roles: response.idTokenClaims.roles
        },
        idToken: response.idToken,
        accessToken: response.accessToken
    }
    store.commit('user', user)
}
async function silentToken() {
    const account = auth.getAllAccounts();

    const accessTokenRequest = {
        scopes: ['openid', 'profile', 'User.Read'],
        account: account[0],
        forceRefresh: true
    };

    try {
        const response = await auth.acquireTokenSilent(accessTokenRequest);
        storeUser(response);
    } catch (error) {
        try {
            //2nd attempt?
            const response = await auth.acquireTokenRedirect(accessTokenRequest);
            storeUser(response);
        } catch (error) {
            // Handle error appropriately
            console.error("Failed to acquire token", error);
        }
    }
}