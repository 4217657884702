<template>
    <section>
        <div class="login-page">
            <div class="grid-container">
                <div class="grid">
                    <img v-for="(i, index) in covers" :key="index" loading="lazy" :src="getImagePath(i)" />
                </div>
            </div>

            <div class="login-container">
                <div class="column has-text-centered animate__animated animate__fadeIn" style="z-index: 99">
                    <img src="../assets/connectlogo.png" />
                    <h1 class="title is-4">Welcome to Connect</h1>
                    <button class="button is-block is-medium button-is-half" style="background-color: #363636; color: white;" @click="login">
                        <span>Login</span>
                        <span class="icon is-small is-pulled-right">
                            <i class="mdi mdi-login-variant"></i>
                        </span>
                    </button>
                    <p class="description">Login with your Jellyfish credentials</p>

                </div>
            </div>


        </div>

        <footer class="footer">
            <div class="container-fluid">
                <div class="level">
                    <div class="level-left">
                        <img src="../assets/connectlogo.png" style="width:50%" />
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <div class="footer-copyright">
                                <b>&copy; {{ year }}, Jellyfish Connect</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </section>
</template>

<script>
    import { auth } from '@/services/msal-service'
    import { mapState } from 'vuex'
    import Axios from 'axios'

    export default {
        name: 'Login',
        data() {
            return {
                covers: null
            }
        },
        created() {
            this.latestIssues()
            if (this.user !== null && this.user !== undefined) {
                var dt = new Date(this.user.expiresOn);
                if (dt > new Date()) {
                    if (this.userRole === 'CustomerAdminFull' || this.userRole === 'CustomerAdminBasic') {
                        this.$router.push({ name: 'CustomerAdmin' })
                    } else {
                        this.$router.push({ path: '/home' })
                    }
                }
            }
        },
        computed: {
            year() {
                return this.$dayjs().year()
            },
            ...mapState(['user', 'userRole'])
        },
        methods: {
            getImagePath(slug) {
                return `https://files.coverscdn.com/imgix-covers/${slug}`;
            },
            latestIssues() {
                var that = this;
                Axios.get(`Generic/issues/latest-covers?limit=100`).then(function (response) {
                    that.covers = response.data
                });
            },
            login() {
                var loginRequest = {
                    scopes: ['openid', 'profile', 'User.Read']
                }

                if (this.user !== null) {
                    var tokenExp = new Date(this.user.expiresOn)
                    if (new Date() >= new Date(tokenExp)) {    
                        auth.loginRedirect(loginRequest)
                    } else {
                        if (this.userRole === 'CustomerAdminFull' || this.userRole === 'CustomerAdminBasic') {
                            this.$router.push({ name: 'CustomerAdmin' })
                        } else {
                            this.$router.push({ path: '/home' })
                        }
                    }

                } else {
                    auth.loginRedirect(loginRequest);
                }
            },
        }
    }
</script>

<style scoped>
    body {
        padding: 0 !important;
        overflow:hidden;
    }
    html{
        overflow: hidden;
        padding-top: 0 !important;
    }
  
    .login-page{
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        height: 100vh;
    }

    .login-container {
    z-index: 1200;

        width: 500px;
        height: 250px;
        background: rgba(255,255,255,0.8);
        border-radius: 10px;
    }

    
    .grid-container {
        z-index:1000;
        opacity:0.8;
        position: absolute;
        top: -200px;
        width: 150vw;
        height: 150vh;
        overflow: hidden;
    }

    .grid {
      display: flex;
      flex-wrap: wrap;
      transform: rotateX(10deg) rotateZ(10deg);
      transform-style: preserve-3d;
    }

    .grid img {
      width: 200px; /* Adjust the size as needed */
      height: auto;
      margin: 10px;
      border-radius: 5px;
      box-shadow: 5px 5px 15px 5px rgba(0,0,0,.21)
    }


    .button-is-half{
        min-width:50%;
        margin:0 auto;
    }

    .footer{
        bottom: 0;
        position: fixed;
        width: 100%;
        background: rgba(255,255,255,0.5);
        z-index:1100;

    }
    .tile-covers {
        display: flex;
        flex-wrap: wrap;
        width: 150vw;
        height: 25vh;
/*        margin-top: 30vh;*/
        /* margin-left: -63vh; */
        /* transform: scaleX(1.2) scaleY(1.2) scaleZ(1.3) rotateX(70deg) rotateY(-5deg) rotateZ(48deg) translateX(265px) translateY(-515px) translateZ(-100px) skewX(8deg) skewY(8deg); */
    }

        .tile-covers img {
            margin-left: 60px;
            margin-bottom: 60px;
            width: 110px;
            height: 130px;
            box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.21);
        }
</style>