import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { auth } from '@/services/msal-service'
import Login from '../views/Login.vue'
import 'core-js/stable';

Vue.use(Router)

const routes = [
  {
    meta: {
        title: 'Login',
        authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
    },
    path: '/',
    name: 'Login',
    component: Login
    },
    {
        path: '/login-success',
        name: 'LoginSuccess',
        component: () => import(/* webpackChunkName: "basics" */ '../views/LoginSuccess.vue')
    },
  {
    meta: {
      title: 'Home',
      authType: ['AdminUser', 'StandardUser']
    },
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "basics" */ '../views/Home.vue')

    },
    /*LEFT NAVIGATION PAGES - CLIENT ADMIN */
    {
        meta: {
            title: 'Manage Title',
            authType: 'AdminUser'
        },
        path: '/manage-title',
        name: 'ManageTitle',
        component: () => import(/* webpackChunkName: "manage-title" */ '../views/ClientAdmin/ManageTitle.vue'),
        props: true
    },
    {
        meta: {
            title: 'Manage Clients',
            authType: 'AdminUser',
        },
        path: '/manage-clients',
        name: 'ManageClients',
        component: () => import(/* webpackChunkName: "clients" */ '../views/ClientAdmin/ManageClients.vue'),
        props: true
    },
    {
        meta: {
            title: 'Offer Management',
            authType: 'AdminUser'
        },
        path: '/offer-management',
        name: 'OfferManagement',
        component: () => import(/* webpackChunkName: "offermanagement" */ '../views/ClientAdmin/OfferManagement.vue'),
        props: true
    },
    {
        meta: {
            title: 'Manage Title Collection',
            authType: 'AdminUser'
        },
        path: '/manage-title-collections',
        name: 'ManageTitleCollections',
        component: () => import(/* webpackChunkName: "collections" */ '../views/ClientAdmin/ManageTitleCollections.vue'),
        props: true
    },

    /*LEFT NAVIGATION PAGES - PRODUCTION */
    {
        meta: {
            title: 'Rasterization Queue Monitor',
            authType: 'AdminUser',
        },
        path: '/issue-rasterization-queue-monitor',
        name: 'RasterizationQueueMonitor',
        component: () => import(/* webpackChunkName: "rasterizer" */ '../views/Production/RasterizationQueueMonitor.vue'),
        props: true
    },
    {
        meta: {
            title: 'Internal Schedule',
            authType: 'AdminUser',
        },
        path: '/internal-schedule',
        name: 'InternalSchedule',
        component: () => import(/* webpackChunkName: "schedule" */ '../views/Production/InternalSchedule.vue'),
        props: true
    },
    {
        meta: {
            title: 'FileCollectorSettings',
            authType: 'AdminUser'
        },
        path: '/file-collector-settings',
        name: 'FileCollectorSettings',
        component: () => import(/* webpackChunkName: "filecollector" */ '../views/Production/FileCollectorSettings.vue')
    },
    {
        meta: {
            title: 'Bulk Ingestor View',
            authType: 'AdminUser'
        },
        path: '/bulk-ingestor-view',
        name: 'BulkIngestorView',
        component: () => import(/* webpackChunkName: "bulkingestor" */ '../views/Production/BulkIngestorView.vue'),
        props: true
    },
    {
        meta: {
            title: 'Manage XCago Issues Status',
            authType: 'AdminUser',
        },
        path: '/xcago-issues-status',
        name: 'ManageXCagoIssuesStatus',
        component: () => import(/* webpackChunkName: "titletransfer" */ '../views/Production/ManageXCagoIssuesStatus.vue'),
        props: true
    },
    /*LEFT NAVIGATION PAGES - Promotions*/
    {
        meta: {
            title: 'Promotions',
            authType: 'AdminUser'
        },
        path: '/promotions',
        name: 'Promotions',
        component: () => import(/* webpackChunkName: "freeissue" */ '../views/Promotions/Promotions.vue')
    },
    {
        meta: {
            title: 'One Time View Links',
            authType: 'AdminUser'
        },
        path: '/one-time-links',
        name: 'OneTimeViewLinks',
        component: () => import(/* webpackChunkName: "onetimeview" */ '../views/Promotions/OneTimeViewLinks.vue'),
        props: true
    },
    {
        meta: {
            title: 'Gift Code Generator',
            authType: 'AdminUser'
        },
        path: '/gift-generator',
        name: 'GiftGenerator',
        component: () => import(/* webpackChunkName: "giftcode" */ '../views/Promotions/GiftsGenerator.vue'),
        props: true
    },
    {
        meta: {
            title: 'Create Trigger Offer',
            authType: 'AdminUser',
        },
        path: '/trigger-offers',
        name: 'TriggeredOffers',
        component: () => import(/* webpackChunkName: "triggeroffers" */ '../views/Promotions/TriggeredOffers.vue'),
        props: true
    },


    /*SITE ADMIN*/
    {
        meta: {
            title: 'Manage Help & Faqs',
            authType: 'AdminUser'
        },
        path: '/manage-help-and-faqs',
        name: 'ManageHelpAndFaqs',
        component: () => import(/* webpackChunkName: "helpandfaqs" */ '../views/SiteAdmin/ManageHelpAndFaqs.vue'),
        props: true
    },
    {
        meta: {
            title: 'Pocketmags Banners',
            authType: 'AdminUser'
        },
        path: '/manage-pocketmags-banners',
        name: 'ManagePocketmagsBanners',
        component: () => import(/* webpackChunkName: "pocketmagssetup" */ '../views/SiteAdmin/ManagePocketmagsBanners.vue'),
        props: true
    },
    {
        meta: {
            title: 'Pocketmags Left Menu',
            authType: 'AdminUser',
        },
        path: '/pocketmags-left-menu',
        name: 'PocketmagsLeftMenu',
        component: () => import(/* webpackChunkName: "pocketmagssetup" */ '../views/SiteAdmin/PocketmagsLeftMenu.vue'),
        props: true
    },
    {
        meta: {
            title: 'Client Uploader',
            authType: 'AdminUser',
        },
        path: '/client-uploader',
        name: 'ClientUploader',
        component: () => import(/* webpackChunkName: "client" */ '../views/SiteAdmin/ClientUploader.vue'),
        props: true
    },
    {
        meta: {
            title: 'Archive Sites',
            authType: 'AdminUser',
        },
        path: '/archive-sites',
        name: 'ArchiveSites',
        component: () => import(/* webpackChunkName: "archivesites" */ '../views/SiteAdmin/ArchiveSites.vue'),
        props: true
    },
    {
        meta: {
            title: 'View Sites',
            authType: 'AdminUser'
        },
        path: '/minisites/view-sites',
        name: 'ViewSites',
        component: () => import(/* webpackChunkName: "minisiteaaa" */ '../views/SiteAdmin/ViewSites.vue')
    },
    {
        meta: {
            title: 'Add or Edit Help Item',
            authType: 'AdminUser'
        },
        path: '/add-edit-help-item',
        name: 'AddEditHelpItem',
        component: () => import(/* webpackChunkName: "helpandfaqs" */ '../views/SiteAdmin/AddEditHelpItem.vue'),
        props: true
    },
    {
        meta: {
            title: 'Create Archive Sites',
            authType: 'AdminUser',
        },
        path: '/create-archive-sites/:id',
        name: 'CreateArchiveSite',
        component: () => import(/* webpackChunkName: "archivesites" */ '../views/SiteAdmin/CreateArchiveSite.vue'),
        props: true
    },

    //PLUS SECTION
    {
        meta: {
            title: 'Manage Plus Titles',
            authType: 'AdminUser'
        },
        path: '/manage-plus-titles',
        name: 'ManagePlusTitles',
        component: () => import(/* webpackChunkName: "plus" */ '../views/Plus/PlusTitles.vue')
    },
    {
        meta: {
            title: 'Manage Plus Titles Sort Order',
            authType: 'AdminUser'
        },
        path: '/plus-titles-sort-order',
        name: 'ManagePlusTitlesSortOrder',
        component: () => import(/* webpackChunkName: "plus" */ '../views/Plus/PlusTitlesSortOrder.vue')
    },
    {
        meta: {
            title: 'Plus Sales Report',
            authType: 'AdminUser'
        },
        path: '/plus-sales-report',
        name: 'PlusSalesReport',
        component: () => import(/* webpackChunkName: "plus" */ '../views/Plus/PlusSalesReport.vue')
    },

    //Apps Section
    {
        meta: {
            title: 'Manage Vendors',
            authType: ['AdminUser']
        },
        path: '/apps/manage-vendors',
        name: 'ManageVendors',
        component: () => import(/* webpackChunkName: "usermanagement" */ '../views/Apps/ManageVendors.vue'),
        props: true
    },
    {
        meta: {
            title: 'Manage Google Vendors',
            authType: ['AdminUser']
        },
        path: '/apps/manage-google-vendors',
        name: 'ManageGoogleVendors',
        component: () => import(/* webpackChunkName: "usermanagement" */ '../views/Apps/ManageGoogleVendors.vue'),
        props: true
    },
    {
        meta: {
            title: 'Transporter Status Ready',
            authType: ['AdminUser', 'StandardUser']
        },
        path: '/apps/transporter-status/ready',
        name: 'TransporterStatusReady',
        component: () => import(/* webpackChunkName: "transporter" */ '../views/Apps/TransporterStatusReady.vue')
    },
    {
        meta: {
            title: 'Transporter Status Errors',
            authType: ['AdminUser', 'StandardUser']
        },
        path: '/apps/transporter-status/errors',
        name: 'TransporterStatusErrors',
        component: () => import(/* webpackChunkName: "transporter" */ '../views/Apps/TransporterStatusErrors.vue')
    },
    {
        meta: {
            title: 'Transporter Status Errors Android',
            authType: ['AdminUser', 'StandardUser']
        },
        path: '/apps/transporter-status/errors/android',
        name: 'TransporterStatusErrorsAndroid',
        component: () => import(/* webpackChunkName: "transporter" */ '../views/Apps/TransporterStatusErrorsAndroid.vue')
    },
    {
        meta: {
            title: 'Title Graphics',
            authType: ['AdminUser', 'StandardUser']
        },
        path: '/apps/title-graphics',
        name: 'TitleGraphics',
        component: () => import(/* webpackChunkName: "titlegraphics" */ '../views/Apps/TitleGraphics.vue'),
        props: true
    },
    {
        meta: {
            title: 'Manage InApp Purchase Strings',
            authType: ['AdminUser', 'StandardUser']
        },
        path: '/apps/manage-in-app-purchase-strings',
        name: 'ManageInAppPurchaseStrings',
        component: () => import(/* webpackChunkName: "inapppurchases" */ '../views/Apps/ManageInAppPurchaseStrings.vue'),
        props: true
    },

    //Customer Service

    {
        meta: {
            title: 'Profile',
            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
        },
        path: '/customer/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "customerservices" */ '../views/CustomerService/Profile.vue')
    },
    {
        meta: {
            title: 'Admin - Refund Reports',
            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
        },
        path: '/customer/refund-reports',
        name: 'RefundReports',
        component: () => import(/* webpackChunkName: "reports" */ '../views/CustomerService/Reports/RefundReports.vue')
    },
    {
        meta: {
            title: 'Cancellation Reports',
            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
        },
        path: '/customer/cancellation-report',
        name: 'CancellationReport',
        component: () => import(/* webpackChunkName: "reports" */ '../views/CustomerService/Reports/SubCancellationReport.vue')
    },
    {
        meta: {
            title: 'External CSA Monitor',
            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
        },
        path: '/customer/externalcsamonitor',
        name: 'ExternalCSAMonitor',
        component: () => import(/* webpackChunkName: "monitor" */ '../views/CustomerService/ExternalCSAMonitor.vue')
    },
    {
        meta: {
            title: 'Check API Subscriber',
            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
        },
        path: '/customer/checkapisubscriber',
        name: 'CheckAPISubscriber',
        component: () => import(/* webpackChunkName: "api" */ '../views/CustomerService/CheckAPISubscriber.vue')
    },
    {
        meta: {
            title: 'Check Gift Codes',
            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
        },
        path: '/customer/checkgiftcode',
        name: 'CheckGiftCode',
        component: () => import(/* webpackChunkName: "giftcodes" */ '../views/CustomerService/CheckGiftCode.vue')
    },
    {
        meta: {
            title: 'Merge Users',
            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
        },
        path: '/customer/mergeusers',
        name: 'MergeUsers',
        component: () => import(/* webpackChunkName: "customerservices" */ '../views/CustomerService/MergeUsers.vue')
    },
    {
        meta: {
            title: 'Edit User',
            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
        },
        path: '/customer/user/:id',
        name: 'user.edit',
        component: () => import(/* webpackChunkName: "customerservices" */ '../views/CustomerService/UserManagement.vue'),
        props: true
    },
    {
        meta: {
            title: 'ClientPocketmagsTitles',
            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
        },
        path: '/customer/ClientPocketmagsTitles',
        name: 'ClientPocketmagsTitles',
        component: () => import(/* webpackChunkName: "customerservices" */ '../views/CustomerService/ClientPocketmagsTitles.vue')
    },
    {
        meta: {
            title: 'Third Party Promotions',
            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
        },
        path: '/customer/thirdpartypromotions',
        name: 'ThirdPartyPromotions',
        component: () => import(/* webpackChunkName: "promos" */ '../views/CustomerService/ThirdParty/ThirdPartyPromotions.vue')
    },
    {
        meta: {
            title: 'Third Party Promo Codes',
            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
        },
        path: '/customer/thirdpartypromo/:id',
        name: 'ThirdPartyPromoCodes',
        component: () => import(/* webpackChunkName: "promos" */ '../views/CustomerService/ThirdParty/ThirdPartyPromoCodes.vue'),
        props: true
    },
    {
        meta: {
            title: 'Customer Search',
            authType: ['AdminUser', 'StandardUser', 'CustomerAdminFull', 'CustomerAdminBasic']
        },
        path: '/customer',
        name: 'CustomerAdmin',
        component: () => import(/* webpackChunkName: "customerservices" */ '../views/CustomerService/CustomerAdmin.vue')
    },
    //Misc Tools
    {
        meta: {
            title: 'Script Runner',
            authType: 'AdminUser',
        },
        path: '/tools/script-runner',
        name: 'ScriptRunner',
        component: () => import(/* webpackChunkName: "tools" */ '../views/MiscTools/ScriptRunner.vue'),
        props: true
    },
    {
        meta: {
            title: 'Reviews',
            authType: 'AdminUser',
        },
        path: '/tools/reviews',
        name: 'Reviews',
        component: () => import(/* webpackChunkName: "tools" */ '../views/MiscTools/Reviews.vue'),
        props: true
    },
    {
        meta: {
            title: 'Data Monitor',
            authType: ['AdminUser', 'StandardUser']
        },
        path: '/monitor/dashboard',
        name: 'DataMonitor',
        component: () => import(/* webpackChunkName: "datamonitor" */ '../views/MiscTools/DataMonitorDashboard.vue')
    },
    {
        meta: {
            title: 'Missing In App Strings',
            authType: ['AdminUser', 'StandardUser']
        },
        path: '/monitor/inappstrings',
        name: 'InAppStrings',
        component: () => import(/* webpackChunkName: "datamonitor" */ '../views/MiscTools/InAppStrings.vue')
    },

    //Finance
    {
        meta: {
            title: 'Sales Uploaders & Generators',
            authType: ['AdminUser', 'StandardUser']
        },
        path: '/finance',
        name: 'Finance',
        component: () => import(/* webpackChunkName: "finance" */ '../views/Finance/Finance.vue')

    },
    {
        meta: {
            title: 'Create Statements',
            authType: ['AdminUser', 'StandardUser']
        },
        path: '/new-statements',
        name: 'NewStatements',
        component: () => import(/* webpackChunkName: "finance" */ '../views/Finance/NewStatements.vue')

    },


    //OTHER??
    {
        meta: {
            title: 'Authorise Push',
            authType: ['AdminUser', 'StandardUser']
        },
        path: '/authorise-push',
        name: 'AuthorisePush',
        component: () => import(/* webpackChunkName: "basics" */ '../views/AuthorisePush.vue')

    },

    {
        meta: {
            title: 'Edit Collection',
            authType: 'AdminUser'
        },
        path: '/edit-collections/:id',
        name: 'EditCollection',
        component: () => import(/* webpackChunkName: "collections" */ '../views/Collections/EditCollection.vue'),
        props: true
    },
    {
        meta: {
            title: 'Bulk Schedule Upload',
            authType: 'AdminUser',
        },
        path: '/schedule/bulk',
        name: 'ScheduleBulk',
        component: () => import(/* webpackChunkName: "schedule" */ '/src/components/templates/SchedulesBulkUpload.vue'),
        props: true
    },



    //MINI SITES subpages

    {
        meta: {
            title: 'Menu & Pages',
            authType: 'AdminUser'
        },
        path: '/minisites/menuandpages/:siteId/:titlelongname',
        name: 'MenuAndPages',
        component: () => import(/* webpackChunkName: "minisitesbbb" */ '../views/ViewSites/MenuAndPages.vue')
    },
    {
        meta: {
            title: 'Site Images',
            authType: 'AdminUser'
        },
        path: '/minisites/site-images/:siteId/:titlelongname',
        name: 'SiteImages',
        component: () => import(/* webpackChunkName: "minisitesccc" */ '../views/ViewSites/SiteImages.vue')
    },
    {
        meta: {
            title: 'Edit Page',
            authType: 'AdminUser'
        },
        path: '/viewsites/edit-page/:siteId/:blogId/:titlelongname',
        name: 'EditPage',
        props: true,
        component: () => import(/* webpackChunkName: "minisitesddd" */ '../views/ViewSites/EditPage.vue')
    },
    {
        meta: {
            title: 'View Title Issues',
            authType: 'AdminUser'
        },
        path: '/minisites/title/:id',
        name: 'ViewTitleIssues',
        props: true,
        component: () => import(/* webpackChunkName: "minisiteseee" */ '../views/ViewSites/ViewTitleIssues.vue')
    },

    {
        meta: {
            title: 'Create Mini Site',
            authType: 'AdminUser'
        },
        path: '/minisites/:id',
        name: 'MiniSiteFlow',
        props: true,
        component: () => import(/* webpackChunkName: "minisitesfff" */ '../components/templates/MiniSiteFlow.vue')
    },


]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})


//LOGIN SITE

router.beforeEach(async (to, from, next) => {
    var tag = document.getElementById('htmltag')
    let classLst = ['has-aside-mobile-transition', 'has-navbar-fixed-top', 'has-aside-left', 'has-aside-expanded', 'has-aside-mobile-expanded']
    if (to.name !== 'Login' && to.name !== 'LoginSuccess') {
        tag.classList.add(...classLst)
    } else {
        tag.classList.remove(...classLst)
    }

    if (to.name === 'Login' || to.name == 'LoginSuccess') {
        next()
        return;
    } else {
        var role = store.state.userRole

        if (role !== undefined && to.meta.authType !== undefined && to.meta.authType.includes(role)) {
            next();
        }
        else {
        }

    } 
})

export default router
