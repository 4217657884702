import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
import VuexReset from '@ianwalter/vuex-reset'

//import moduleCached from '@/store/cached.js'
//store.registerModule('cached', moduleCached);

Vue.use(Vuex)


const MiniSiteCreate = {
    namespaced: true,
    state: {
        miniSiteData: {
            isSaveLoad: false,
            percentCompleted: 0,
            siteId: null,
            addJmagMiniSiteModalActive: false,
            isAddJMAGMiniSitePolicyModalActive: false,
            isAddBlogSiteModalActive: false,
            pocketmagsTitles: [],
            minisitesPoliciesList: [],
            blogSitesList: [],
            siteTemplatesList: [],
            imageTypesList: [],
            keepFirst: false,
            openOnFocus: true,
            clearable: true,
            policyName: '',
            returnPolicyName: '',
            tandCPolicyName: '',
            cookiePolicyName: '',
            blogsiteName: '',
            form: {
                titleid: 0,
                altCover1File: {},
                altCover2File: {},
                altCover3File: {},
                bgimageFile: {},
                faviconFile: {},
                contactHeader: '<h1>Contact Us</h1>',
                copyrightfooter: 'magazine.co.uk',
                searchbar: false,
                indexfollow: false,
                loginicon: false,
                displayOnAdmin: false,
                showdownload: false,
                showribbon: false
            },
            miniSitesClassesForm: {
                siteid: 0,
                backgroundCol: '#f2f2f2',
                leftmenuBgcolLink: '#ffffff',
                leftmenuBgcolHover: '#000000',
                leftmenuTxtcolLink: '#000000',
                leftmenuTxtcolHover: '#ffffff',
                standardTxtcolLink: '#2a5192',
                standardTxtcolHover: '#527fc9',
                h1TxtSizeWeb: 24,
                h1TxtSizeMob: 18,
                h1Txtcol: '#000000',
                ctaTxtcol: '#ffffff',
                ctaTxtcolHover: '#ffffff',
                ctaBgcolLink: '#8e231a',
                ctaBgcolHover: '#af3028',
                ctaPosition: '0px',
                cssFile: '',
                headerBgCol: '#ffffff',
                headerBgColLink: '#ffffff',
                headerBgColHover: '#ffffff',
                cssOverride: ''
            },
            miniSitesImagesForm: {
                siteid: 0,
                siteTemplateId: null,
                imagelocationFile: {},
                imagelocationMobFile: {},
                displayfrom: new Date(new Date()),
                displayto: new Date(new Date().setFullYear(new Date().getFullYear() + 30)),
                sortorder: 1,
                imagealt: '',
                imagelink: '/',
                backgroundcol: '#ffffff'
            },
            miniSitesImagesImageTypeSelect: false,

        }
    },
    mutations: {
        basic(state, payload) {
            state.miniSiteData[payload.key] = payload.value
        },
    },
    actions: {
        loadLists({ commit }) {
            axios.all([
                axios.get('Title/get-all-pocketmags-titles'),
                axios.get('MiniSites/get-mini-site-policies-list'),
                axios.get('MiniSites/get-blog-sites-list'),
                axios.get('MiniSites/get-mini-site-sitetemplates-list'),
                axios.get('MiniSites/get-mini-site-imagetypes-list')
            ])
                .then(axios.spread((responsePKMGTitles, responsePolicies, responseBlogSites, responseSiteTemplates, responseImageTypes) => {
                    commit('basic', { key: 'pocketmagsTitles', value: responsePKMGTitles.data })
                    commit('basic', { key: 'minisitesPoliciesList', value: responsePolicies.data })
                    commit('basic', { key: 'blogSitesList', value: responseBlogSites.data })
                    commit('basic', { key: 'siteTemplatesList', value: responseSiteTemplates.data })
                    commit('basic', { key: 'imageTypesList', value: responseImageTypes.data })

                }))
        },
        createSite({ state }) {
            state.miniSiteData.isSaveLoad = true
            let formData = new FormData();

            for (var key in state.miniSiteData.form) {
                if (key === 'bgimageFile' || key === 'faviconFile' || key === 'altCover1File' || key === 'altCover2File' || key === 'altCover3File')
                    formData.append(key, state.miniSiteData.form[key])
                else
                    formData.append(`jMAGMiniSite[${key}]`, state.miniSiteData.form[key]);
            }


            Object.keys(state.miniSiteData.miniSitesClassesForm).forEach(key => {
                if (state.miniSiteData.miniSitesClassesForm[key] !== null) {
                    if (state.miniSiteData.miniSitesClassesForm[key].toString().charAt(0) === '#') {
                        state.miniSiteData.miniSitesClassesForm[key] = state.miniSiteData.miniSitesClassesForm[key].toString().substring(1)
                    }
                }
            })
            for (var keyC in state.miniSiteData.miniSitesClassesForm) {
                formData.append(`jMAGMiniSiteClasses[${keyC}]`, state.miniSiteData.miniSitesClassesForm[keyC]);
            }

            state.miniSiteData.miniSitesImagesForm.backgroundcol = state.miniSiteData.miniSitesImagesForm.backgroundcol.toString().substring(1)
            state.miniSiteData.miniSitesImagesForm.siteTemplateId = state.miniSiteData.form.siteTemplate
            state.miniSiteData.miniSitesImagesForm.displayto = state.miniSiteData.miniSitesImagesForm.displayto.toISOString().slice(0, 10)
            state.miniSiteData.miniSitesImagesForm.displayfrom = state.miniSiteData.miniSitesImagesForm.displayfrom.toISOString().slice(0, 10)
            for (var keyI in state.miniSiteData.miniSitesImagesForm) {
                if (keyI === 'imagelocationFile' || keyI === 'imagelocationMobFile')
                    formData.append(key, state.miniSiteData.miniSitesImagesForm[keyI])
                else
                    formData.append(`jMAGMiniSiteImages[${keyI}]`, state.miniSiteData.miniSitesImagesForm[keyI]);
            }

            axios.post('MiniSites/create-jmag-mini-site', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                state.miniSiteData.isSaveLoad = true
                alert('success')
            }).catch(() => {
            })
        },
        loadMiniSite({ state }, siteId) {

            axios.all([
                axios.get(`MiniSites/mini-site/${siteId}/mini-site`),
                //axios.get(`MiniSites/mini-site/${siteId}/mini-site-images`),
                axios.get(`MiniSites/mini-site/${siteId}/mini-site-classes`)
            ]).then(axios.spread((responseMiniSite, responseMiniSiteClasses) => {
                state.miniSiteData.form = responseMiniSite.data
                state.miniSiteData.miniSitesClassesForm = responseMiniSiteClasses.data
                //state.miniSiteData.miniSitesImagesForm = responseMiniSiteImages.data

            }))

        }
    }
}

export default new Vuex.Store({
  plugins: [
    createPersistedState({
            // storage: window.sessionStorage,
            // only persist these fields (related to login) a refresh should reload all other fields
        paths: ['user', 'userRole','userRawIdToken',
            'clientTitlesBase',
            'titleBaseDropdown',
            'allTitles',
            'allVendors',
            'googleVendors',
            'defaultTitle',
            'pricingTiers',
            'pricingTierExpiry',
            'TrialLengths',
            'filteredPlatforms',
            'topPlatforms',
            'allCurrencies',
            'countries',
            'languages',
            'revenueTerritories',
            'titleCategories',
            'titleSubCategories',
            'allClients',
            'defaultClient',
            'pageLoading']
    }),
    VuexReset()
  ],
  state: {
    /* User */
    user: null,
    userName: null,
    userEmail: null,
    userAvatar: null,
    userRole: null,
    userToken: null,
    userRawIdToken: null,
    userSid: null,

    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false,

    /* Dark mode */
    isDarkModeActive: false,

    /*data loaded from API*/
    clientTitlesBase: null,
    allVendors: null,
    googleVendors: null,
    allIntroducers: null,
    allStores: null,
    allTitles: null,
    allClients: null,
    allContactRoles: null,
    titleBaseDropdown: null,
    allCurrencies: null,
    pricingTiers: null,
    pricingTierExpiry: null,
    TrialLengths: [],
    countriesExpiry: null,
    countries: [],
    languages: [],
    filteredPlatforms: null,
    topPlatforms: null,
    revenueTerritories: null,
    titleCategories: null,
    titleSubCategories: null,

    titleSchedules: [],
    titleCollections: [],
    pageLoading: false,

    defaultTitle: null,
    defaultClient: null
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },
    /* User */
    user (state, payload) {
      if (payload === null) {
        state.user = payload
        state.userName = null;
        state.userEmail = null;
        state.userAvatar = null;
        state.userRole = null;
        state.userToken = null;
        state.userRawIdToken = null;
        state.userSid = null;
      } else {
        var name = payload.account.name.split(' ')

        if (payload.account.name) {
          state.user = payload
          state.userName = payload.account.userName
        }
        if (payload.account.userName) {
          state.userEmail = payload.account.userName
        }
        if (payload.avatar) {
          state.userAvatar = `https://eu.ui-avatars.com/api/?name=${name[0]}+${name[1]}`
        }
        if (payload.idTokenClaims.roles.length > 0) {
          state.userRole = payload.idTokenClaims.roles[0]
        }
        if (payload.idToken) {
          state.userRawIdToken = payload.idToken
        }
        if (payload.expiresOn) {
          state.tokenExpires = payload.expiresOn
        }
        if (payload.sid) {
          state.userSid = payload.account.sid
        }
      }
    },
    userEmail(state, payload) {
        state.userName = payload
        state.userEmail = payload
    },
    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'
      const classLst = ['has-aside-left', 'has-aside-expanded']
      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
        document.documentElement.classList.add(...classLst)
      } else {
        document.documentElement.classList.remove(htmlClassName)
        document.documentElement.classList.remove(...classLst)
      }

      state.isAsideMobileExpanded = isShow
    },

    /* Dark Mode */
    darkModeToggle (state) {
      const htmlClassName = 'is-dark-mode-active'

      state.isDarkModeActive = !state.isDarkModeActive

      if (state.isDarkModeActive) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }
    },

    SET_VENDORS(state, vendors) {
        state.allVendors = vendors
    },
    SET_GOOGLE_VENDORS(state, vendors) {
        state.googleVendors = vendors
    },
    SET_INTRODUCERS(state, introducers) {
        state.allIntroducers = introducers
    },
    SET_STORES(state, list) {
        state.allStores = list
    },
    SET_TITLES(state, list) {
        state.allTitles = list
    },
    SET_CLIENTS(state, list) {
        state.allClients = list
    },
    SET_CONTACTROLES(state, list) {
        state.allContactRoles = list
    },
    SET_CURRENCIES(state, list){
        state.allCurrencies = list
    },
    SET_PRICINGTIERS(state, tiers){
       state.pricingTiers = tiers 

       var expiryDate = new Date();
       expiryDate.setDate(expiryDate.getDate() + 2);
       state.pricingTierExpiry = expiryDate;
    },
    SET_TRIALLENGTHS(state, payload) {
      state.TrialLengths = payload
    },
    SET_FILTEREDPLATFORMS(state, payload) {
      state.filteredPlatforms = payload
    },
    SET_TOPPLATFORMS(state, payload) {
      state.topPlatforms = payload
    },
    SET_TITLEBASE_DROPDOWN(state, payload){
      state.titleBaseDropdown = payload
    },
    setSelectedTitle(state, payload) {
      state.defaultTitle = payload;
    },
    setSelectedClient(state, payload) {
      state.defaultClient = payload;
    },
    storeCountries(state, payload) {
      state.countries = payload;

      var expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 2);
      state.countriesExpiry = expiryDate;
    },
    SET_LANGUAGES(state, payload) {
      state.languages = payload
    },

    setPageLoading(state, isLoading) {
      state.pageLoading = isLoading;
    },
    storeTitleSchedules(state, payload) {
      Vue.set(state.titleSchedules, payload.titleId, payload.data);
    },

    storeTitleCollections(state, payload) {
        Vue.set(state.titleCollections, payload.titleId, payload.data);
    },
    storeClientTitlesBase(state, payload) {
      state.clientTitlesBase = payload
    },
    refreshDefaultTitle(state) {
      //if the default title doesn't match the title list then reset it to first in the title list
      if (state.defaultTitle === null || !state.clientTitlesBase.some(function (p) { return p.id === state.defaultTitle.id })) {
          //not set to one of the titles, so update it to first one (maybe switch logins caused this, or access to title removed?)
          var defaultTitle = state.clientTitlesBase.find(o => o.isLive === true);
          if (defaultTitle === undefined)
              defaultTitle = state.clientTitlesBase.find(o => o.isLive === false);
          state.defaultTitle = defaultTitle;

      }
    },
    SET_REVENUE_TERRITORIES(state, payload) {
        state.revenueTerritories = payload;
    },
    SET_TITLE_CATEGORIES(state, payload) {
        state.titleCategories = payload;
      },
    SET_TITLE_SUBCATEGORIES(state, payload) {
        state.titleSubCategories = payload;
    },
  },
  actions: {
    getUserRole (state) {
      return state.userRole
    },
    getUserToken (state) {
        return state.userToken
    },
    async getVendors({ state, commit }) {
        if (!state.allVendors) {
            await axios.get('/generic/all-vendors')
                .then(response => {
                    //response.data.push({ id: null, name: "Show All" });
                    commit('SET_VENDORS', response.data)

                });
        }
    },
    async getGoogleVendors({ state, commit }) {
        if (!state.googleVendors) {
            await axios.get('/generic/google-vendors')
                .then(response => {
                    commit('SET_GOOGLE_VENDORS', response.data)
                });
        }
      },
    async getIntroducers({ state, commit }) {
        if (!state.allIntroducers) {
            await axios.get('/generic/all-introducers')
                .then(response => {
                    commit('SET_INTRODUCERS', response.data)
                });
        }
      },
    async getStores({ state, commit }) {
          if (!state.allStores) {
              await axios.get('/CustomerAdmin/GetStores')
                  .then(response => {
                      commit('SET_STORES', response.data)
                  });
          }
      },
    async getTitleBaseDropdown({ commit }){
        commit('setPageLoading', true)
        await axios.get('/title/titlebase-dropdown').then(async response => {
            commit('SET_TITLEBASE_DROPDOWN', response.data);

            var LiveTitles = response.data.filter((item) => item.isLive)
            commit('setSelectedTitle', LiveTitles[1])
            commit('setPageLoading', false)
        });
    },
    async getTitles({ state, commit }) {
          if (state.allTitles === null || state.allTitles.length === 0) {
              state.defaultTitle = null; //clear default as we reloading it

              var success = await axios.get('/title/get-all-titles')
                  .then(async response => {
                      commit('SET_TITLES', response.data);

                      if (response.data.length === 0) {
                          return false;
                      } else {
                          //var LiveTitles = response.data.filter((item) => item.isLive)
                          //commit('setSelectedTitle', LiveTitles[1])
                          return true;
                      }
                  });
              return success
          } else {
              //var LiveTitles = state.allTitles.filter((item) => item.isLive)
              //commit('setSelectedTitle', LiveTitles[1])
              return state.allTitles;
          }
      },
    async getClients({ state, commit }) {
        if (!state.allClients) {
            await axios.get('/generic/all-clients')
                .then(response => {
                    commit('SET_CLIENTS', response.data)
                    commit('setSelectedClient', response.data[0])
                });
        }
    },
    async getClientsForce({ commit }) {
        await axios.get('/generic/all-clients')
            .then(response => {
                commit('SET_CLIENTS', response.data)
                commit('setSelectedClient', response.data[0])
            });
    },
    getContactRoles({ state, commit }) {
          if (!state.allContactRoles) {
              axios.get('/client/contact-roles')
                  .then(response => {
                      commit('SET_CONTACTROLES', response.data)
                  });
          }
      },


    getCurrencies({ state, commit }) {
          if (!state.allCurrencies) {
              axios.get('/generic/currencies')
                  .then(response => {
                      commit('SET_CURRENCIES', response.data)
                  });
          }
      },
    getPricingTiers({ state, commit }) {
          if (!state.pricingTiers) {
              axios.get('Generic/pricing-tiers')
                  .then(response => {
                      commit('SET_PRICINGTIERS', response.data)
                  });
          }
      },
    getTrialLengths({ state, commit }) {
        if (state.TrialLengths === null || state.TrialLengths.length === 0) {
            axios.get(`Generic/trial-lengths`).then(function (response) {
                var TrialLengths = response.data.map(function (r) {
                    return {
                        Id: r.id,
                        Name: r.length,
                        Days: r.numberOfDays,
                        Months: r.numberOfMonths
                    }
                });
                commit('SET_TRIALLENGTHS', TrialLengths)
            })
        }
      },
    getPlatforms({ state, commit }) {
        if (!state.filteredPlatforms) {
            axios.get('Generic/filtered-platforms')
                .then(response => {
                    commit('SET_FILTEREDPLATFORMS', response.data)
                });
        }

      },
    getTopPlatforms({ commit }) {
        axios.get('Generic/top-platforms').then(response => {
          commit('SET_TOPPLATFORMS', response.data)
        });
      },
    loadCountries({ state, commit }) {
          if (state.countries === null || state.countries.length === 0 || state.countriesExpiry == null || state.countriesExpiry < new Date()) {
              axios.get(`Generic/countries`)
                  .then(function (response) {
                      commit('storeCountries', response.data)
                  })
          }
      },
    getLanguages({ state, commit }) {
          if (state.languages.length === 0) {
              axios.get(`Generic/languages`)
                  .then(function (response) {
                      commit('SET_LANGUAGES', response.data)
                  })
          }
      },

    loadPricingTiers({ state, commit }) {
        if (state.pricingTiers === null || state.pricingTiers.length === 0 || state.pricingTierExpiry == null || state.pricingTierExpiry < new Date()) {
            axios.get('Generic/pricing-tiers')
                .then(function (response) {
                  commit('SET_PRICINGTIERS', response.data)
                })
        }
      },
    async loadTitleSchedule({ state, dispatch }, search) {

        if (search.filter.titleId in state.titleSchedules) {
            return state.titleSchedules[search.filter.titleId];
        } else {
            return dispatch("loadTitleScheduleForce", search);
        }
      },

    async loadTitleScheduleForce({ state, commit }, search) {
        return axios
            .get(`Schedule/title/${search.filter.titleId}/issue-schedules?viewPastSchedules=${search.filter.viewPastSchedules}`)
            .then(function (response) {
                commit('storeTitleSchedules', { data: response.data, titleId: search.filter.titleId });
                return state.titleSchedules[search.filter.titleId];
            })
    },

    async loadClientUserTitles({ commit }, clientId) {
    
      var success = await axios.get(`Title/client/${clientId}/titles-by-client`)
           .then(async response => {
               commit('storeClientTitlesBase', response.data);

               if (response.data.length === 0) {
                   return false;
               } else {
                   commit('refreshDefaultTitle');
                   return true;
               }
           });
       return success
      },

    getRevenueTerritories({ state, commit }) {
      if (state.revenueTerritories === null || state.revenueTerritories.length === 0) {
          axios.get('Generic/revenue-territories').then((response) => {
              commit('SET_REVENUE_TERRITORIES', response.data)
          })
      }
    },

    getCategories({ state, commit }) {
        if (state.titleCategories === null || state.titleCategories.length === 0) {
            axios.all([
                axios.get('Generic/title-categories'),
                axios.get('Generic/title-sub-categories')
            ])
                .then(axios.spread((obj1, obj2) => {
                    commit('SET_TITLE_CATEGORIES', obj1.data)
                    commit('SET_TITLE_SUBCATEGORIES', obj2.data)
                }))
        }
    },
  },
  getters: {
  },
  modules: {
    MiniSiteCreate: MiniSiteCreate
  }
})
